.gallary-container{
    width: 100%;
    height: 100vh;
    /* margin-top: 5rem; */
    /* padding: 1.5rem; */
    padding: 5rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    overflow: scroll;
}
.gallary-container::-webkit-scrollbar{
    display: none;
}
.upload-img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.gallary-images{
    width: 380px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    /* border: 1px solid grey; */
    border-radius: 15px;
    overflow: hidden;
    /* padding: .4rem; */
}
.gallary-images img{
    width: 100%;
    height: 100%;
    /* max-height: 280px; */
    border-radius: 10px;
}

.gallary_pop_container{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(15px);
    position: absolute;
    top:0%;
    left: 0%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.gallary_pop_container::-webkit-scrollbar{
    display: none;
}
.gallary_pop_container img{
    height: 70vh;
    max-width: 90%;
    border-radius: 1.2rem;
}

@media screen and (max-width:765px) {
    .gallary-container{
        padding: 5rem .5rem 1rem .5rem;
        gap: .8rem;
    }
    .gallary-images{
        width: 40%;
        height: fit-content;
    }
    .gallary_pop_container img{
        height: auto;
        width: 95%;
    }
    .gallary-images{
        border-radius: 10px;
        width: 45%;
    }
}