.teacher_photo_container {
    width: 100%;
    height: 85vh;
    background: black;
    color: white;
    padding: 1rem 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}
.teacher_photo_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}
.teacher_photo_header h2{
    font-size: 5vw;
    color: black;
    -webkit-text-stroke-width: 1px ;
    -webkit-text-stroke-color: white;
}
.teacher_link{
    font-size: 18px;
    color: black;
    padding: .5rem 1.8rem;
    background: white;
    border-radius: 2em;
    text-decoration: none;
}
.teachers_container{
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
}
.teachers_container img{
    width: 49%;
    border-radius: 1.2rem;
}

@media screen and (max-width:765px) {
    .teacher_photo_container{
        padding: 1rem .5rem;
    }
    .teacher_photo_header h2{
        font-size: 42px;
    }
    .teacher_link{
        padding: .3rem 1rem;
        font-size: 16px;
    }
    .teachers_container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .teachers_container img{
        width: 100%;
        height: auto;
        border-radius: 15px;
    }
}