.card-section-container{
    width: 100%;
    height: auto;
    margin: 5rem 0;
}
.card-section{
    display: flex;
    gap: 1.3rem;
    align-items: center;
    justify-content: space-between;
}
.card {
    width: 100%;
    height: fit-content;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 15px;
    box-shadow: 8px 8px 10px rgba(0,0,0,0.1), -5px -5px 10px rgba(0,0,0,0.1);
}
.card-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    border-radius: 15px;
}
.card-img h2{
    font-size: 5rem;
}
.card-text{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .6rem;
}
.card-text p{
    font-size: 18px;
    font-weight: 600px;
    color: grey;
}
.card-text h6{
    font-size: 28px;
}
.card1 .card-img{
    background: url("../../../../../public/image/bg-1.jpg");
    background-size: cover;
    background-position: center;
}
.card2 .card-img{
    background: url("../../../../../public/image/bg-2.jpg");
    background-size: cover;
    background-position: center;
}
.card3 .card-img{
    background: url("../../../../../public/image/bg-3.jpg");
    background-size: cover;
    background-position: center;
}
@media screen and (max-width:765px) {
    .card-section{
        display: flex;
        flex-direction: column;
    }
}