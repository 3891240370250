.img-section-container{
    width: 100%;
    height: 80vh;
    /* background-image: url("../../../../../public/image/computeLab.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.stripe-container{
    width: 100%;
    height: 80px;
    background: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(10deg) scale(1.2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.stripe-container h1{
    font-size: 3.6vw;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    word-wrap:break-word;
    min-width: 100px;
}
.stripe-container h1 span{
    width: 12px;
    height: 12px;
    background: black;
    border-radius: 50%;
    display: block;
}

@media screen and (max-width:765px) {
    .stripe-container{
        height: 60px;
    }
    .stripe-container h1{
        font-size: 15px;
        gap: .6rem;
    }
    .stripe-container h1 span{
        width: 8px;
        height: 8px;
    }
}