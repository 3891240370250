.footer-container {
    padding: 1.2rem 1rem;
    background: black;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    position: relative;
    height: auto;
}
.footer-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15%;
    left: 0;
    // border: 1px solid red;
}
.footer-logo-container img {
    width: 140px;
    background: black;
    padding: 1rem;
    border-radius: 50%;
}
.footer-container .name-logo {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 7vw;
    color: black;
    margin-top: 4rem;
    text-transform: uppercase;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    font-family: sans-serif;
}
.footer-wrapper {
    width: 90%;
    margin: 0 auto;
    height: auto;
    padding: 1.2rem 1rem;
    border-radius: 2rem;
    background: rgba(71, 71, 71, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-texts h1 {
    font-size: 5rem;
    color: white;
}
.footer-links {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-end;
}

.contact-container {
    h1 {
        font-size: 23px;
        color: grey;
    }
}
.icons-footer {
    display: flex;
    gap: 1.2rem;
    font-size: 32px;
    color: white;
}
.address-footer {
    font-size: 18px;
    width: 300px;
    color: grey;
    border: 1px;
}
.address-footer span {
    color: white;
}

@media screen and (max-width: 765px) {
    .footer-container{
        padding-bottom: 4rem;
    }
    .footer-wrapper {
        width: 98%;
        flex-direction: column;
    }
    .footer-links{
        align-items: center;
        padding-top: 2rem;
    }
}

