.number-section-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}
.input-box{
    width: 70%;
    height: 130px;
    border: 4px solid black;
    box-shadow: 8px 8px 0px black;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input-btn{
    width: 15%;
    height: 100%;
    border-left: 4px solid black;
    background: #0094FF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}
.input-box input{
    width: 100%;
    height: 56px;
    border: none;
    font-size: 32px;
    font-weight: 600;
    outline: none;
}
.padding{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
@media screen and (max-width:765px) {
    .input-box{
        width: 90%;
        height: 70px;
    }
    .input-box input{
        height: 100%;
    }
    .input-btn{
        width: 30%;
        font-size: 32px;
    }
    .padding{
        padding: .8rem;
    }
}