.tour-section-container{
    width: 92%;
    height: 70vh;
    margin: 3rem auto;
    display: flex;
}
.tour-text-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}
.tour-text-container h1{
    font-size: 5vw;
    color: white;
    -webkit-text-stroke-width:2px ;
    -webkit-text-stroke-color: black;
    font-family: sans-serif;
}
.tour-text-container h1 span{
    color: black;
}
.tour-image-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
}
.tour-image-container img{
    width: 15%;
    height: 80%;
    transition: .4s all;
    border-radius: 5rem;
}
.tour-image-container img:hover{
    width: 50%;
    border-radius: 1rem;
}

@media screen and (max-width:765px) {
    .tour-section-container{
        flex-direction: column;
    }
    .tour-text-container h1{
        font-size: 60px;
    }
    .tour-image-container{
        justify-content: center;
    }
    .tour-image-container img{
        width: 20%;
        height: 100%;
    }
}