.header-container {
    width: 99%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.header-container .header-img {
    width: 100%;
    height: 78vh;
    border: 2px solid black;
    border-radius: 20px;
    overflow: hidden;
}

.header-img img {
    width: 100%;
    height: 100%;
}

.slogan {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: grey;
    margin-top: .2rem;
}

.slogan h2 {
    font-size: 18px;
}

.header-text-container{
    width: 95%;
    display: flex;
    margin-top: .5rem;
    gap: 1rem;
}
.header-text-container .header-tag{
    padding: .4rem 1.5rem;
    width: fit-content;
    font-size: 15px;
    color: white;
    font-weight: 600;
    background: black;
    border-radius: 2rem;
    text-transform: uppercase;
}
.header-text-container h2{
    width: 100%;
    font-size:6vw;
    text-transform: capitalize;
    color: #A90606;
}
.header-text-container p{
    font-size: 16px;
    width: 100%;
    margin-top: 2rem;
}
@media screen and (max-width:765px) {
    .header-container .header-img {
        height: 40vh;
        width: 96%;
    }

    .header-img img {
        object-fit: cover;
    }
    .header-text-container{
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }
    .header-text-container h2{
        width: 100%;
        font-size: 15vw;
        color: #A90606;
    }
    .header-text-container p{
        font-size: 18px;
        width: 100%;
        margin-top:0;
    }
}