.addmission-btn-container{
    z-index: 100;
    padding: .2rem;
    background: white;
    border-radius: 2rem;
    position: fixed;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%,0);
    color: white;
    display: flex;
    border: 2px solid black;
    text-decoration: none;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.2);
}
.addmission-btn-container h5{
    font-size: 20px;
    color: black;
    padding: .5rem 2rem;

}
.addmission-btn-container h6{
    font-size: 20px;
    background: #322822;
    border-radius: 2rem;
    padding: .5rem 2rem;
    color: white;
    text-decoration: none;
}