.home-container{
    width: 100%;
    margin: 0 auto;
    margin-top: .3rem;
    box-sizing: border-box;
}
.home-container::-webkit-scrollbar{
    display: none;
}
.home-wrapper{
    width: 85%;
    margin: 0 auto;
    box-sizing: border-box;
}
@media screen and (max-width:765px) {
    .home-wrapper{
        width: 90%;
    }
}