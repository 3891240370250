.focus-section-container{
    width: 100%;
    height: 60vh;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.section-header{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.section-header h1{
    font-size: 5vw;
    -webkit-text-stroke-width:2px;
    -webkit-text-stroke-color:black;
    color: white;
    font-family: sans-serif;
}
.section-header h2{
    font-size: 5vw;
}


.tags-slider-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tags-slider-container .slider{
    width: 100%;  
    display: flex;
    align-items: center;
    gap: 1.2rem;
    overflow: scroll;
}
.slider::-webkit-scrollbar{
    display: none;
}
.tag-container{
    padding: .8rem 2rem;
    border-radius: 2rem;
    font-size: 20px;
    font-weight: 600;
    background: black;
}
@media screen and (max-width:765px) {
    .section-header{
        width:90%;
    }
    .section-header h1{
        font-size: 50px;
    }
    .section-header h2{
        font-size: 50px;
    }
    .tags-slider-container .slider{
        gap: .6rem;
    }
    .tag-container{
        padding: .5rem 1.4rem;
    }
}