@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@600&family=Quicksand:wght@400;500&display=swap');

.App {
  width: 100%;
  height: 100vh;
  font-family: 'Mulish', sans-serif;
  user-select: none;
  box-sizing: border-box;
}

.App::-webkit-scrollbar {
  display: none;
}
.active{
  background: #0075FF;
}

.scroll-none{
  overflow: hidden;
}