.big-card-container{
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.big-card{
    width: 80%;
    background: rgba(0,0,0,0.1);
    /* border: 2px solid black; */
    border-radius: 2rem;
    height: 85%;
    overflow: hidden;
    padding: .2rem;
    display: flex;
}
.big-card-text{
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.big-card-text h2{
    font-size: 5vw;
    font-weight: 600;
    color: #ff0000;
}
.big-card-text h3{
    font-size: 4vw;
    color: #0075FF ;
    text-transform: uppercase
}
.big-card-image{
    width: 100%;
    padding: .5rem;
    box-sizing: border-box;
}
.big-card img{
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}
.btn-position{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
@media screen and (max-width:765px) {
    .big-card{
        height: 80%;
        width: 100%;
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column-reverse;
    }
    .big-card-image{
        height: 100%;
    }
    .big-card-image img{
        border-radius: 15px;
    }
    .big-card-text{
        height: 100%;
    }
    .big-card-text h2{
        font-size: 50px;
    }
    .big-card-text h3{
        font-size: 35px;
    }
}