.all-image-container{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: white;
}
.upload-div{
    width: 200px;
    height: 58px;
    font-size: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 123, 255);
    position: absolute;
    left: 50%;
    top: 1.5%;
    z-index: 1000;
    transform: translate(-50%,0%);
    border-radius: 10px;
}
.all-image-wrapper{
    height: 100%;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    padding-top: 5rem;
    overflow: scroll;
    justify-content: center;
    /* background: white; */
}
.all-image-wrapper::-webkit-scrollbar{
    display: none;
}
.images{
    width: 250px;
    height: 300px;
    height: fit-content;
    border: 1px solid black;
    background: white;
    padding: .5rem;
    border-radius: 15px;
    position: relative;
    margin-top: 1rem;
}
.images img{
    width: 100%;
    border-radius: 10px;
}
.delete-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: -10%;
    color: white;
    transform: translate(-50%,0);
}