.password-container{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.2);
    position: absolute;
    top:0;
    left: 0;
    z-index: 999999;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.password-card{
    width: 30%;
    padding: 1.5rem;
    background-color: white;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.password-input{
    width: 100%;
    height: 50px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
    color: black;
    padding: 0 1rem;
}
.password-card button{
    font-size: 18px;
    font-weight: 600;
    color: white;
    background: black;
    padding: .8rem 2.5rem;
    border: none;
    border-radius: 10px;
}
.password-card button:hover{
    opacity: .7 ;
    cursor: pointer;
}