.open {
  width: 600px;
  height: 530px;
  transition: 0.3s all;
}

.closed {
  width: 200px;
  height: 60px;
  transition: 0.3s all;
}

.nav-container {
  overflow: hidden;
  background: black;
  border-radius: 15px;
  position: fixed;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.2rem 1rem;
  z-index: 100;
}
.nav-container .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-container .logo img {
  width: 60px;
}
.nav-container .menu {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.nav-container .menu ul {
  margin: 0 auto;
  width: 100%;
  list-style: none;
  color: white;
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}
.nav-container .menu ul .navlink {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: 0.4s all;
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.nav-container .menu ul .navlink:hover {
  background: #0075FF;
}
.nav-container .menu .menu-image-container {
  width: 100%;
  opacity: 0.8;
  margin: 0 auto;
  height: 300px;
  border-radius: 1rem;
  overflow: hidden;
}
.nav-container .menu .menu-image-container img {
  width: 100%;
  height: 100%;
}
.nav-container .close-nav-btn {
  margin: 0 auto;
  width: 90%;
  height: 56px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 2rem;
}

@media screen and (max-width: 765px) {
  .open {
    width: 90%;
    height: 530px;
  }
  .nav-container .menu {
    width: 95%;
  }
}/*# sourceMappingURL=nav.css.map */