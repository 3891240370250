.dashboard-container{
    width: 100%;
    height: 100vh;
}
.dash-nav{
    width: 90%;
    margin: 0 auto;
    height: 65px;
    background: black;
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%,0);
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0 1.5rem;
    z-index: 10;
}
.dash-nav .link{
    color: white;
    text-decoration: none;
    display: flex;
    gap: .6rem;
    align-items: center;
}
.construction-container{
    width: 100%;
    height: 100vh;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}